body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 41%;
  }
  100% {
    background-position: 0% 60%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 41%;
  }
  100% {
    background-position: 0% 60%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 60%;
  }
  50% {
    background-position: 100% 41%;
  }
  100% {
    background-position: 0% 60%;
  }
}
